import revive_payload_client_4sVQNw7RlN from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/atlassian/pipelines/agent/build/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_5j40ijIZYu from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import plugin_gab9g3XuEx from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt-aos/dist/runtime/plugin.js";
import plugin_8SbxDRbG6Y from "/opt/atlassian/pipelines/agent/build/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_tMGwffvjBc from "/opt/atlassian/pipelines/agent/build/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import plugin_AUP22rrBAc from "/opt/atlassian/pipelines/agent/build/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import directives_8CcCirWtnE from "/opt/atlassian/pipelines/agent/build/plugins/directives.ts";
import recaptcha_85gNSCNFUU from "/opt/atlassian/pipelines/agent/build/plugins/recaptcha.ts";
import axios_interceptor_rLOERlBzLj from "/opt/atlassian/pipelines/agent/build/plugins/axios-interceptor.ts";
import globals_qEnHRCYwqu from "/opt/atlassian/pipelines/agent/build/plugins/globals.ts";
import pusher_T6fDwDIeLY from "/opt/atlassian/pipelines/agent/build/plugins/pusher.ts";
import toaster_3RwauQEG5B from "/opt/atlassian/pipelines/agent/build/plugins/toaster.ts";
import vuedatepicker_oKNl9XPOyX from "/opt/atlassian/pipelines/agent/build/plugins/vuedatepicker.ts";
import vuetify_7h9QAQEssH from "/opt/atlassian/pipelines/agent/build/plugins/vuetify.ts";
import plugin_auto_pageviews_client_x3Y8XIaaPY from "/opt/atlassian/pipelines/agent/build/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_5j40ijIZYu,
  plugin_gab9g3XuEx,
  plugin_8SbxDRbG6Y,
  plugin_tMGwffvjBc,
  plugin_AUP22rrBAc,
  directives_8CcCirWtnE,
  recaptcha_85gNSCNFUU,
  axios_interceptor_rLOERlBzLj,
  globals_qEnHRCYwqu,
  pusher_T6fDwDIeLY,
  toaster_3RwauQEG5B,
  vuedatepicker_oKNl9XPOyX,
  vuetify_7h9QAQEssH,
  plugin_auto_pageviews_client_x3Y8XIaaPY
]