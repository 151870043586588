import { defineStore } from 'pinia';
import axios from "axios";
import type {Contact, FaqCategory, FaqQuestion, Page, Review} from "~/types/Page";

export const usePageStore = defineStore('page', {
    state: () => ({
        staticPage: {} as Page,
        contactData: {} as Contact,
        faqCategories: [] as FaqCategory[],
        faqQuestions: [] as FaqQuestion[],
        reviews: [] as Review[],
    }),
    actions: {
        async fetchPage(slug: string): Promise<Page|null> {
            try {
                const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/v1/content-pages/${slug}`);
                if (response.data.data) {
                   return response.data.data as Page;
                }
            } catch (error) {

            }
            return null;
        },
        async getStaticPage(slug: string): Promise<Page|null> {
            const tmpPage = await this.fetchPage(slug);
            if (tmpPage) this.staticPage = tmpPage;
            return this.staticPage;
        },
        async fetchContactData(): Promise<Contact|null> {
            try {
                const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/v1/contact`);
                if (response.data.data) {
                    return response.data.data;
                }
            } catch (error) {

            }
            return null;
        },
        async getContactData(): Promise<Contact|null> {
            if (!Object.keys(this.contactData).length) {
                const tmpContactData = await this.fetchContactData();
                if (tmpContactData) this.contactData = tmpContactData;
            }
            return this.contactData;
        },
        async fetchQuestionsForCategoryId(categoryId: number): Promise<FaqQuestion[]|null> {
            try {
                const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/v1/faq-questions/category/${categoryId}`);
                if (response.data.data) {
                    return response.data.data;
                }
            } catch (error) {

            }
            return null;
        },
        async fetchQuestions(): Promise<FaqQuestion[]|null> {
            try {
                const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/v1/faq-questions`);
                if (response.data.data) {
                    return response.data.data;
                }
            } catch (error) {

            }
            return null;
        },
        async getQuestions(): Promise<FaqQuestion[]|null> {
            if (!this.faqQuestions.length) {
                const tmpQuestions = await this.fetchQuestions();
                if (tmpQuestions) this.faqQuestions = tmpQuestions;
            }
            return this.faqQuestions;
        },
        async fetchFaqCategories(): Promise<FaqCategory[]|null> {
            try {
                const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/v1/faq-categories`);
                if (response.data.data) {
                    return response.data.data;
                }
            } catch (error) {

            }
            return null;
        },
        async getFaqCategories(): Promise<FaqCategory[]|null> {
            if (!this.faqCategories.length) {
                const tmpFaqCategories = await this.fetchFaqCategories();
                if (tmpFaqCategories) this.faqCategories = tmpFaqCategories;
            }
            return this.faqCategories;
        },
        async fetchReview(limit: number): Promise<Review[]|null> {
            try {
                const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/v1/reviews/random/${limit}`);
                if (response.data.data) {
                    return response.data.data;
                }
            } catch (error) {

            }
            return null;
        },
        async getReviews(limit: number): Promise<Review[]|null> {
            if (!this.reviews.length) {
                const tmpReviews = await this.fetchReview(limit);
                if (tmpReviews) this.reviews = tmpReviews;
            }
            return this.reviews;
        }
    },
    persist: {
        storage: persistedState.sessionStorage,
        paths: [
            'faqQuestions',
            'faqCategories',
            'reviews',
        ],
        key: 'pageStore',
    }
});
