import { storeToRefs } from 'pinia';
import { useAuthStore } from "~/plugins/store/auth";

export default defineNuxtRouteMiddleware((to) => {
    const { authenticated } = storeToRefs(useAuthStore()); // make authenticated state reactive
    const authResponse = useCookie('authResponse'); // get token from cookies
    if (authResponse.value) {
        // check if value exists
        // todo verify if token is valid, before updating the state
        authenticated.value = true; // update the state to authenticated
    }

    // if token exists and url is /login redirect to homepage
    if (authResponse.value) {
        return navigateTo('/');
    }
});
