<script lang="ts">
import {defineComponent, onMounted, ref, watch} from 'vue';
import MasterLayout from "~/layouts/MasterLayout.vue";
import RegistrationPlate from "~/components/vehicle/RegistrationPlate.vue";
import {useTranslationStore, translation} from '~/plugins/store/translations';
import type {TranslationList} from "~/types/Utils";
import {useProductStore} from "~/plugins/store/product";
import {createDefaultRcaVehicleFormValidation, type RcaVehicleFormValidation} from "~/types/Vehicle";
import type {ProductStatuses} from "~/types/Product";
import {usePageStore} from "~/plugins/store/page";
import {useRcaStore} from "~/plugins/store/rca";
import type {RcaInsuranceCompany} from "~/types/Rca";
import type {FaqQuestion, FaqCategory, Review} from "~/types/Page";

export default defineComponent({
  setup() {
    useHead({
      title: '24Broker',
      meta: [
        {
          name: 'description',
          content: '24Broker - Cea mai ușoară si rapidă soluție pentru compararea online a polițelor de asigurare RCA, de călătorie, pentru locuințe și multe altele. Economisește timp și bani cu noi!'
        }
      ],
    });

    const pageStore = usePageStore();
    const faqCategories = ref<FaqCategory[] | null>([]);
    const faqQuestions = ref<FaqQuestion[] | null>([]);
    const reviews = ref<Review[] | null>([]);
    const {$toast} = useNuxtApp();
    const translationStore = useTranslationStore();
    const translationList = ref<TranslationList>({});
    const productStore = useProductStore();
    const productStatuses = ref<ProductStatuses>(productStore.productStatuses);
    const faqTab = ref("0");
    const playAnimations = ref(false);

    const rcaStore = useRcaStore();
    const rcaInsuranceCompanies = ref<RcaInsuranceCompany[]>([]);

    const registrationPlate = ref('');
    const validationVehicleStatus = ref<RcaVehicleFormValidation>(createDefaultRcaVehicleFormValidation());
    validationVehicleStatus.value.registration_plate = true;

    onMounted(async () => {
      translationList.value = await translationStore.getTranslations('ro', 'dashboard');

      rcaInsuranceCompanies.value = await rcaStore.getAllRcaInsuranceCompanies();

      faqCategories.value = await pageStore.getFaqCategories();

      faqQuestions.value = await pageStore.getQuestions();

      reviews.value = await pageStore.getReviews(3);

      playAnimations.value = true;
    });


    const handleValidationVehicleStatus = ({field, isValid}: { field: string, isValid: boolean }) => {
      if (field === 'registration_plate') {
        validationVehicleStatus.value[field] = isValid;
      }
    };

    const isValidRegistrationPlate = () => {
      return !!validationVehicleStatus.value.registration_plate;
    };

    // Metoda de filtrare a întrebărilor pe baza categoriei curente
    const filteredQuestions = (categoryId: number) => {
      if (faqQuestions.value) {
        return faqQuestions.value.filter(q => q.category_id === categoryId);
      }
      return [];
    };

    return {
      translationList,
      translation,
      registrationPlate,
      rcaInsuranceCompanies,
      productStatuses,
      handleValidationVehicleStatus,
      isValidRegistrationPlate,
      faqTab,
      faqCategories,
      faqQuestions,
      filteredQuestions,
      reviews,
      playAnimations
    };
  },
  components: {RegistrationPlate, MasterLayout},
  data: () => ({}),
});
</script>

<template>
  <master-layout class="index">
    <v-container class="content-container">
      <!-- Main container -->
      <v-container fluid class="component-container-1400mw">
        <v-container>
          <v-row class="d-flex justify-center">
            <v-col cols="12" sm="12" md="6" lg="8" xl="8">
              <div class="d-flex flex-column h-100">
                <div>
                  <div class="my-auto">
                    <div class="fw-700 fs-48">{{
                        translation(translationList.we_compare, "Noi comparăm")
                      }}<span class="text-primary">.</span></div>
                    <div class="fw-700 fs-48">
                      {{
                        translation(translationList.you_decide, "Tu decizi ce ți se potrivește")
                      }}<span class="text-primary">.</span>
                    </div>
                  </div>
                </div>
                <div class="mt-auto">
                  <v-card rounded="0" color="primary-light" flat class="cursor-auto pulse animated"
                          v-show="productStatuses.is_rca_available">
                    <v-row class="my-4">
                      <v-col class="d-flex align-center justify-center flex-column">
                        <div class="icon-rca"></div>
                        <div class="fw-700 fs-16">RCA</div>
                      </v-col>
                    </v-row>
                    <v-row justify="center" class="mx-5 my-3">
                      <v-col cols="12" sm="12" md="12" lg="9" xl="9">
                        <div>
                          <registration-plate
                              ref="inputRegistrationPlate"
                              v-model="registrationPlate"
                              @validation-vehicle-status="handleValidationVehicleStatus"
                          ></registration-plate>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" lg="3" xl="3">
                        <NuxtLink
                            :to="registrationPlate.length === 0 ? (isValidRegistrationPlate() ? '/rca' : '') : `/rca/${registrationPlate}`"
                            class="text-decoration-none text-black">
                          <v-btn
                              block
                              color="primary"
                              size="x-large"
                              rounded="0"
                          >
                            {{ translation(translationList.continue, "Continuă") }}
                          </v-btn>
                        </NuxtLink>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-row class="mt-2">
                    <v-col cols="12" sm="6" md="12" lg="6" xl="6"
                           v-show="productStatuses.is_house_insurance_available">
                      <NuxtLink to="/house" class="text-decoration-none text-black">
                        <v-card rounded="0" color="primary-light" flat link class="cursor-pointer pulse animated">
                          <v-row class="my-4">
                            <v-col class="d-flex align-center justify-center flex-column">
                              <div class="icon-house"></div>
                              <div class="fw-700 fs-16">
                                {{ translation(translationList.houses, "Locuințe") }}
                              </div>
                            </v-col>
                          </v-row>
                        </v-card>
                      </NuxtLink>
                    </v-col>
                    <v-col cols="12" sm="6" md="12" lg="6" xl="6" v-show="productStatuses.is_travel_available">
                      <NuxtLink to="/travel" class="text-decoration-none text-black">
                        <v-card rounded="0" color="primary-light" flat link class="cursor-pointer pulse animated">
                          <v-row class="my-4">
                            <v-col class="d-flex align-center justify-center flex-column">
                              <div class="icon-travel"></div>
                              <div class="fw-700 fs-16">
                                {{ translation(translationList.travel, "Călătorie") }}
                              </div>
                            </v-col>
                          </v-row>
                        </v-card>
                      </NuxtLink>

                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="4" xl="4" class="visible-on-web">
              <NuxtImg
                  src="/images/homepage-insurance.webp"
                  srcset="/images/homepage-insurance-small.webp 480w, /images/homepage-insurance.webp 1024w"
                  sizes="(max-width: 600px) 480px, 1024px"
                  :class="playAnimations ? 'zoomIn animated' : ''"
                  class="w-100 h-100"
                  fit="cover"
                  alt="Homepage family"
                  loading="lazy"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-container>

      <!-- Insurance companies container -->
      <v-container fluid class="component-container-full">
        <v-container class="container">
          <div class="d-flex justify-center my-5">
            <div class="fw-500 fs-24">{{
                translation(translationList.our_options, "O singură platformă, zeci de opțiuni din care să alegi.")
              }}
            </div>
          </div>
          <v-row class="d-flex align-center justify-center">
            <div class="d-flex flex-wrap align-center justify-center background-primary-light pa-1">
              <v-col cols="auto" sm="auto" md="auto" class="pa-1"
                     v-for="(insuranceCompany, index) in rcaInsuranceCompanies"
                     :key="index">
                <v-card height="80" width="130" rounded="0" flat color="ghost-white"
                        class="d-flex align-center justify-center">
                  <NuxtImg :src="'/images/companies/insurance/thumbnails/' + insuranceCompany.code + '.webp'"
                           :alt="insuranceCompany.name" class="pa-1 w-100 pulse animated"/>
                </v-card>
              </v-col>
            </div>
          </v-row>
        </v-container>
      </v-container>

      <!-- For you container   -->
      <v-container fluid class="component-container-full background-primary-light mt-10">
        <v-container class="container">
          <v-container class="component-container-800mw">
            <div class="d-flex flex-column align-center justify-center">
              <div class="aos-init aos-animate" data-aos="fade-down">
                <div class="color-primary font-caveat fw-700 fs-36">
                  {{ translation(translationList.for_you, "Pentru tine") }}
                </div>
              </div>
              <div class="aos-init aos-animate" data-aos="fade-up">
                <div class="fw-700 fs-36 text-center">
                  {{ translation(translationList.best_decision, "E ușor să iei cea mai bună decizie cu 24Broker.ro") }}
                </div>
              </div>

              <div class="aos-init aos-animate" data-aos="zoom-in">
                <v-row class="mt-10 d-flex align-center">
                  <v-col cols="12" sm="4" md="4" lg="4" class="d-flex centered-on-mobile">
                    <NuxtImg
                        src="/images/homepage-circle-1.webp"
                        srcset="/images/homepage-circle-1.webp 480w, /images/homepage-circle-1.webp 1024w"
                        sizes="(max-width: 600px) 480px, 1024px"
                        style="object-fit: cover; max-width: 180px;"
                        alt="Circle 1"
                        loading="lazy"
                    />
                  </v-col>
                  <v-col cols="12" sm="8" md="8" lg="8" class="d-flex flex-column centered-on-mobile">
                    <div class="fw-700 fs-24">
                      {{ translation(translationList.win_time, "Câștigi timp") }}
                    </div>
                    <div class="fw-500 fs-16">
                      {{
                        translation(translationList.win_time_description, "24Broker.ro compară pentru tine zeci de polițe de asigurare de la furnizori de top, iar tu decizi foarte simplu și rapid care ți se potrivește.")
                      }}
                    </div>
                  </v-col>
                </v-row>
              </div>

              <div class="aos-init aos-animate" data-aos="zoom-in">
                <v-row class="mt-10 d-flex align-center">
                  <v-col cols="12" sm="4" md="4" lg="4"
                         class="visible-on-mobile centered-on-mobile d-flex">
                    <NuxtImg
                        src="/images/homepage-circle-2.webp"
                        srcset="/images/homepage-circle-2.webp 480w, /images/homepage-circle-2.webp 1024w"
                        sizes="(max-width: 600px) 480px, 1024px"
                        style="object-fit: cover; max-width: 180px;"
                        alt="Circle 2"
                        loading="lazy"
                    />
                  </v-col>
                  <v-col cols="12" sm="8" md="8" lg="8"
                         class="d-flex flex-column centered-on-mobile">
                    <div class="fw-700 fs-24">
                      {{ translation(translationList.save_time, "Economisești bani") }}
                    </div>
                    <div class="fw-500 fs-16">
                      {{
                        translation(translationList.save_time_description, "Identifici ușor cele mai avantajoase polițe și alegi potrivit bugetului tău și nevoilor tale.")
                      }}
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" lg="4"
                         class="d-flex centered-on-mobile visible-on-web">
                    <NuxtImg
                        src="/images/homepage-circle-2.webp"
                        srcset="/images/homepage-circle-2.webp 480w, /images/homepage-circle-2.webp 1024w"
                        sizes="(max-width: 600px) 480px, 1024px"
                        style="object-fit: cover; max-width: 180px;"
                        alt="Circle 2"
                        loading="lazy"
                    />
                  </v-col>
                </v-row>
              </div>


              <div class="aos-init aos-animate" data-aos="zoom-in">
                <v-row class="mt-10 d-flex align-center">
                  <v-col cols="12" sm="4" md="4" lg="4" class="d-flex centered-on-mobile">
                    <NuxtImg
                        src="/images/homepage-circle-3.webp"
                        srcset="/images/homepage-circle-3.webp 480w, /images/homepage-circle-3.webp 1024w"
                        sizes="(max-width: 600px) 480px, 1024px"
                        style="object-fit: cover; max-width: 180px;"
                        alt="Circle 3"
                        loading="lazy"
                    />
                  </v-col>
                  <v-col cols="12" sm="8" md="8" lg="8" class="d-flex flex-column centered-on-mobile">
                    <div class="fw-700 fs-24">
                      {{ translation(translationList.get_info, "Iei decizii informate") }}
                    </div>
                    <div class="fw-500 fs-16">
                      {{
                        translation(translationList.get_info_description, "24broker.ro îți oferă informații detaliate despre termenii și condițiile polițelor de asigurare, pentru ca tu să faci alegeri precise și corecte.")
                      }}
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-container>
        </v-container>
      </v-container>

      <v-container fluid class="component-container-full mt-10">
        <v-container>
          <v-container class="component-container-800mw">
            <div class="d-flex flex-column align-center justify-center">
              <div class="aos-init aos-animate d-flex flex-row align-center" data-aos="fade-down">
                <v-divider style="width: 110px; border-style: dashed" thickness="2"></v-divider>
                <v-rating
                    readonly
                    :length="5"
                    :size="32"
                    :model-value="5"
                    color="error"
                    active-color="tertiary"
                />
                <v-divider style="width: 110px; border-style: dashed" thickness="2"></v-divider>
              </div>
              <div class="aos-init aos-animate" data-aos="fade-up">
                <div class="fw-700 fs-36 text-center">
                  {{
                    translation(translationList.review_title, "Peste 5 milioane de polițe emise anual prin 24Broker")
                  }}
                </div>
              </div>
            </div>
          </v-container>

          <v-container>
            <v-row v-if="reviews && reviews.length">
              <v-col cols="12" sm="12" md="4" v-for="(review, index) in reviews" :key="index">
                <v-card
                    class="pa-5 d-flex flex-column h-100 aos-init aos-animate" rounded="0"
                    color="primary-light"
                    data-aos="flip-up"
                    min-height="380" flat>
                  <v-card-title>
                    <v-icon class="fs-48" icon="mdi-format-quote-open"></v-icon>
                  </v-card-title>
                  <v-card-text class="fw-700 fs-16 color-contrast">
                    <div class="my-auto">
                      {{ review.body }}
                    </div>
                  </v-card-text>
                  <v-card-subtitle class="fw-500 fs-14 mt-auto">
                    {{ review.city_name }}, {{ review.author }}
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
          </v-container>

          <v-container class="component-container-800mw">
            <div class="d-flex flex-column align-center justify-center">
              <div class="aos-init aos-animate" data-aos="fade-down">
                <div class="color-primary font-caveat fw-700 fs-36">
                  {{ translation(translationList.with_you, "Alături de tine") }}
                </div>
              </div>
              <div class="aos-init aos-animate" data-aos="fade-up">
                <div class="fw-700 fs-36 text-center">
                  {{ translation(translationList.faq_title, "Întrebări frecvente") }}
                </div>
              </div>
            </div>
            <div class="mt-10">
              <v-tabs
                  class="faq-tab"
                  v-model="faqTab"
                  fixed-tabs
                  center-active
                  bg-color="primary-light"
                  show-arrows
              >
                <v-tab variant="outlined" class="fw-600 text-dark" selected-class="selected" rounded="0"
                       v-for="(faqCategory, index) in faqCategories" :key="index">
                  {{ faqCategory.category }}
                </v-tab>
              </v-tabs>

              <v-tabs-window v-model="faqTab">
                <v-tabs-window-item
                    v-for="(faqCategory, index) in faqCategories"
                    :key="index"
                    :value="index"
                >
                  <v-expansion-panels class="my-2">
                    <v-expansion-panel
                        class="my-2 border-dashed-contrast"
                        rounded="0"
                        v-for="(faqQuestion, qIndex) in filteredQuestions(<number>faqCategory.id)"
                        :key="qIndex"
                    >
                      <template v-slot:title>
                        <div class="fw-700 fs-24">{{ faqQuestion.question }}</div>
                      </template>
                      <template v-slot:text>
                        <div class="fw-500 fs-16">
                          {{ faqQuestion.answer }}
                        </div>
                      </template>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-tabs-window-item>
              </v-tabs-window>
            </div>
          </v-container>
        </v-container>
      </v-container>
    </v-container>
  </master-layout>
</template>

<style scoped>
</style>
