import { defineNuxtPlugin } from '#app'
import axios from 'axios'
import {useProductStore} from "~/plugins/store/product";
import type {ProductStatuses} from "~/types/Product";

export default defineNuxtPlugin((nuxtApp) => {
    axios.interceptors.response.use(
        async response => {

            let tmpProductStatuses = response.headers['x-site-product-statuses'];
            if (tmpProductStatuses) {
               tmpProductStatuses = JSON.parse(tmpProductStatuses) as ProductStatuses;
               const productStore = useProductStore();
               if (productStore.productStatuses.updated_at !== tmpProductStatuses.updated_at) {
                   productStore.productStatuses = tmpProductStatuses;
               }
            }
            return response;
        },
        error => {
            if (error.response && error.response.data && error.response.data.error) {
                const { code, message, pretty_message } = error.response.data.error;
                if (code === 503) {
                    navigateTo('/maintenance');
                }
            }
            return Promise.reject(error);
        }
    )

})