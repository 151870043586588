<script lang="ts">
import {defineComponent, ref, onMounted, computed} from 'vue';
import MasterHeader from '~/layouts/components/MasterHeader.vue';
import MasterFooter from '~/layouts/components/MasterFooter.vue';
import {useAuthStore} from "~/plugins/store/auth";
import { reloadNuxtApp } from "nuxt/app";
import {navigateTo} from "#app";
import ScrollToTop from "~/components/utils/ScrollToTop.vue";
import CookieConsentContainer from "~/components/utils/CookieConsentContainer.vue";

export default defineComponent({
  components: {
    CookieConsentContainer,
    ScrollToTop,
    MasterHeader,
    MasterFooter
  },
  setup() {
    const config = useRuntimeConfig();
    const authStore = useAuthStore();
    const user = computed(() => authStore.getUser());
    const { initialize } = useHotjar();

    const { $pusher } = useNuxtApp();
    const isInMaintenance = ref(false);

    const channel = $pusher.subscribe('maintenance');

    channel.bind('App\\Events\\MaintenanceModeChanged', (data: any) => {
      console.log('MaintenanceModeChanged', data);
      isInMaintenance.value = data.is_in_maintenance;
      if (isInMaintenance.value) {
        return navigateTo('/maintenance');
      } else {
        return navigateTo('/');
      }
    });

    onMounted(() => {
      if (!user) {
        google.accounts.id.initialize({
          client_id: config.public.googleClientId,
          callback: handleCredentialResponse,
        });
        google.accounts.id.prompt();
      }
      if (process.env.NODE_ENV === 'production') {
        initialize(); //hotjar
      }
    });

    const handleCredentialResponse = async (response: any) => {
      const token = response.credential;
      await authStore.loginWithGoogle(token);
      reloadNuxtApp();
    }

    return {

    };
  }
});
</script>

<template>
    <div style="min-width: 350px;" class="fill-height d-flex flex-column">
      <master-header/>
      <div class="fill-height">
        <slot/>
      </div>
      <scroll-to-top></scroll-to-top>
      <cookie-consent-container/>
      <master-footer/>
    </div>
</template>

<style scoped>

</style>
