import { defineStore } from 'pinia';
import type {MaintenanceResponse} from "~/types/Utils";
import axios from "axios";

export const useSystemStore = defineStore('system', {
    state: () => ({
        _fetchMaintenanceStatusPromise: null as Promise<any> | null,
        isDarkTheme: false,
        isThemeChangedManually: false,
        isCookieConsentAccepted: false,
        clientIp: null as string|null,
    }),
    actions: {
        async fetchMaintenanceStatus(): Promise<MaintenanceResponse|null> {
            if (this._fetchMaintenanceStatusPromise) return this._fetchMaintenanceStatusPromise;

            this._fetchMaintenanceStatusPromise = (async () => {
                try {
                    const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/v1/maintenances/status`);
                     if (response.data.data) {
                         return response.data.data;
                     }
                } catch (error) {
                    throw error;
                } finally {
                    this._fetchMaintenanceStatusPromise = null;
                }
            })();

            return this._fetchMaintenanceStatusPromise;
        },
        async storeCookieConsent(consent: boolean, accessToken: string|null, token: string|undefined) {
            this.isCookieConsentAccepted = consent;
            const config = accessToken ? {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'X-FORWARDED-FOR': this.clientIp
                }
            } : {
                headers: {
                    'X-FORWARDED-FOR': this.clientIp
                }
            };

            try {
                await axios.post(`${import.meta.env.VITE_API_BASE_URL}/api/v1/cookie-consents`, {consent, token}, config);
            } catch (error) {
                throw error;
            }
        }
    },
    persist: {
        storage: persistedState.localStorage,
        paths: [
            'isDarkTheme',
            'isThemeChangedManually',
            'isCookieConsentAccepted',
            'clientIp',
        ],
        key: 'systemStore',
    }
});
