<template>
  <v-text-field
      :error-messages="error"
      :label="translation(translationList.registration_plate, 'Număr de înmatriculare')"
      v-model="registrationPlate"
      :rules="plateValidationRules"
      name="registration_plate"
      @input="handleInput"
      variant="outlined"
      bg-color="surface"
      :tile="true"
  >
    <template v-slot:append-inner>
      <v-tooltip location="top" open-on-click open-on-hover open-delay="500">
        <template v-slot:activator="{ props }">
          <v-icon v-bind="props" class="cursor-pointer" aria-label="info">
            mdi-information
          </v-icon>
        </template>
        <span>
          <NuxtImg src="/images/vehicle/registration_template/template_a.png" class="h-100" loading="lazy" alt="Registration template" />
        </span>
      </v-tooltip>
    </template>
  </v-text-field>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted, toRefs } from 'vue';
import { useTranslationStore, translation } from "~/plugins/store/translations";
import type { TranslationList } from "~/types/Utils";

const SPECIAL_PLATE_CODES = 'MAI|A|CD|TC|FA|ALA|CO';
const COUNTY_CODES = 'AB|AR|AG|BC|BH|BN|BR|BT|BV|BZ|CS|CL|CJ|CT|CV|DB|DJ|GL|GR|GJ|HR|HD|IL|IS|IF|MM|MH|MS|NT|OT|PH|SM|SJ|SB|SV|TR|TM|TL|VS|VL|VN|VR';
const NORMAL_PATTERN = new RegExp(`^(${COUNTY_CODES})\\d{2}[A-Z]{3}$`);
const BUCHAREST_PATTERN = /^B\d{2,3}[A-Z]{3}$/;
const SPECIAL_PATTERN = new RegExp(`^(${SPECIAL_PLATE_CODES})\\d{3,6}$`);

export default defineComponent({
  props: {
    modelValue: String as () => string | null,
  },
  setup(props, { emit }) {
    const { modelValue } = toRefs(props);
    const registrationPlate = ref(modelValue.value ? modelValue.value : '');
    const error = ref([]);

    watch(registrationPlate, (newValue) => {
      const isValid = validatePlate(newValue);
      if (isValid) {
        emit('update:modelValue', newValue);
        emit('on-county-code-extracted', extractCountyCode(newValue));
      } else {
        emit('update:modelValue', '');
      }
      emit('validation-vehicle-status', { field: 'registration_plate', isValid });
    });

    watch(modelValue, (newValue) => {
      if (newValue) {
        registrationPlate.value = newValue;
      }
    });

    const translationList = ref<TranslationList>({});
    const translationStore = useTranslationStore();

    const plateValidationRules = [
      (v: string) => validatePlate(v) || translation(translationList.value.invalid_field, 'Acest câmp este invalid.'),
    ];

    function validatePlate(value: string, showErrors?: boolean): boolean {
      error.value = [];
      if (!value && showErrors) {
        error.value = [translation(translationList.value.required, 'Acest câmp este obligatoriu')];
        return false;
      }
      const result = NORMAL_PATTERN.test(value) || BUCHAREST_PATTERN.test(value) || SPECIAL_PATTERN.test(value);
      if (!result && showErrors) {
        error.value = [translation(translationList.value.invalid_field, 'Acest câmp este invalid.')];
      }
      return result;
    }

    function extractCountyCode(value: string): string | null {
      if (NORMAL_PATTERN.test(value)) {
        return value.slice(0, 2);
      } else if (BUCHAREST_PATTERN.test(value)) {
        return 'B'; // Bucharest has a unique code
      }
      return null;
    }

    function handleInput() {
      registrationPlate.value = registrationPlate.value.toUpperCase();
    }

    onMounted(async () => {
      translationList.value = await translationStore.getTranslations('ro', 'dashboard');

      window.addEventListener('validate-vehicle', (data) => {
        if (data.detail && data.detail === 'registration_plate') {
          const isValid = validatePlate(registrationPlate.value, true);
          emit('validation-vehicle-status', { field: 'registration_plate', isValid });
        }
      });
    });

    return {
      registrationPlate,
      plateValidationRules,
      translationList,
      translation,
      error,
      handleInput,
    };
  },
});
</script>

<style scoped>
</style>
