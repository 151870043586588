import axios from "axios";
import { defineStore } from "pinia";
import type {TranslationList, Translations} from "~/types/Utils";


export const useTranslationStore = defineStore('translations', {
    state: () => ({
        translations: {} as Translations,
        _fetchPromise: null as Promise<void> | null, // Private property to track fetch operation
    }),
    actions: {
        async fetchTranslations() {
            // If a fetch operation is already in progress, wait for it
            if (this._fetchPromise) return this._fetchPromise;

            // Initiate a new fetch operation
            this._fetchPromise = (async () => {
                try {
                    const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/v1/translations`);
                    if (response.data.data) {
                        this.translations = response.data.data;
                    }
                } catch (error) {
                    throw error;
                } finally {
                    this._fetchPromise = null; // Reset the promise after completion
                }
            })();

            return this._fetchPromise;
        },
        async getTranslations(activeLocale: string, translationType: string): Promise<TranslationList> {
            if (Object.keys(this.translations).length === 0) {
                await this.fetchTranslations();
            }
            if (Object.keys(this.translations).length !== 0) {
                return this.translations[translationType][activeLocale] as unknown as TranslationList;
            }
            return {};
        }
    },
    persist: {
        storage: persistedState.sessionStorage,
    },
});
export const translation = (translationKey: string, defaultTranslation: string): string => {
    return translationKey || defaultTranslation;
};
