export interface ProductStatuses {
    id: number | null;
    is_ro_vignette_available: boolean;
    is_hu_vignette_available: boolean;
    is_rca_available: boolean;
    is_travel_available: boolean;
    is_house_insurance_available: boolean;
    created_at: string | null;
    updated_at: string | null;
    deleted_at: string | null;
}

export interface Product {
    id: number;
    code: string;
    name: string;
}

export const createDefaultProductStatuses = (): ProductStatuses => {
    return {
        id: null,
        is_ro_vignette_available: false,
        is_hu_vignette_available: false,
        is_rca_available: true,
        is_travel_available: true,
        is_house_insurance_available: true,
        created_at: null,
        updated_at: null,
        deleted_at: null,
    };
}