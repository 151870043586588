import { defineStore } from 'pinia';
import axios from "axios";
import {createDefaultProductStatuses, type Product, type ProductStatuses} from "~/types/Product";

export const useProductStore = defineStore('product', {
    state: () => ({
        productList: [] as Product[],
        productStatuses: createDefaultProductStatuses() as ProductStatuses,
        _fetchProductListPromise: null as Promise<any> | null,
        _fetchProductByCodePromise: null as Promise<any> | null,
        rcaProduct: {} as Product,
        houseInsuranceProduct: {} as Product,
        travelProduct: {} as Product,
    }),
    actions: {
        async fetchProductList() {
            if (this._fetchProductListPromise) return this._fetchProductListPromise;

            this._fetchProductListPromise = (async () => {
                try {
                    const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/v1/products`);
                    if (response.data.data) {
                        this.productList = response.data.data;
                    }
                } catch (error) {
                    throw error;
                } finally {
                    this._fetchProductListPromise = null;
                }
            })();

            return this._fetchProductListPromise;
        },
        async fetchProductByCode(code: string): Promise<Product> {
            if (this._fetchProductByCodePromise) return this._fetchProductByCodePromise;

            this._fetchProductByCodePromise = (async () => {
                try {
                    const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/v1/products/${code}`);
                    if (response.data.data) {
                        return response.data.data;
                    }
                } catch (error) {
                    throw error;
                } finally {
                    this._fetchProductByCodePromise = null;
                }
            })();

            return this._fetchProductByCodePromise;
        },
        async getHouseInsuranceProduct(): Promise<Product> {
            if (Object.keys(this.houseInsuranceProduct).length === 0) {
                this.houseInsuranceProduct = await this.fetchProductByCode('house-insurance');
            }
            return this.houseInsuranceProduct;
        },
        async getRcaProduct(): Promise<Product> {
            if (Object.keys(this.rcaProduct).length === 0) {
                this.rcaProduct = await this.fetchProductByCode('rca');
            }
            return this.fetchProductByCode('rca');
        },
        async getTravelProduct(): Promise<Product> {
            if (Object.keys(this.travelProduct).length === 0) {
                this.travelProduct = await this.fetchProductByCode('travel');
            }
            return this.fetchProductByCode('travel');
        },
        async getProductList() {
            if (Object.keys(this.productList).length === 0) {
                await this.fetchProductList();
            }
            return this.productList;
        },
    },
    persist: {
        storage: persistedState.localStorage,
        paths: [
            'productStatuses',
            'travelProduct',
            'rcaProduct',
            'houseInsuranceProduct',
        ],
        key: 'productStore',
    }
});
