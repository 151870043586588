export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('uppercase', {
        beforeMount(el) {
            el.addEventListener('keyup', function(e: KeyboardEvent) {
                const target = e.target as HTMLInputElement;
                target.value = target.value.toUpperCase();
                // Trigger input event to update v-model
                el.dispatchEvent(new Event('input'));
            });
        }
    });
});
