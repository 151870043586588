<template>
  <v-card
      color="primary"
      class="header"
      rounded="0"
  >
    <div class="visible-on-mobile">
      <v-menu open-on-click v-model="menuOpen" scroll-strategy="close" style="left: -4px;" location="bottom">
        <template v-slot:activator="{ props }">
          <v-btn
              v-bind="props"
              color="contrast"
              size="small"
              rounded="0"
              variant="text"
              :icon="menuOpen ? 'mdi-close' : 'mdi-menu'"
              aria-label="Menu"
          >
          </v-btn>
        </template>
        <template v-slot:default>
          <v-card class="shadow-lg px-3 py-3" rounded="0" min-width="300px" color="primary">
            <v-list bg-color="primary">
              <!-- Loop through mainMenuItems -->
              <template v-for="(item, index) in mainMenuItems" :key="index">
                <!-- If item does not have subMenuItems, render as a list-item -->
                <NuxtLink :to="item.path" class="text-decoration-none color-contrast" v-if="!item.subMenuItems" :aria-label="item.title">
                  <v-list-item
                      exact
                      active-class="item-active">
                    {{ item.title }}
                  </v-list-item>
                </NuxtLink>

                <v-list-group v-else>
                  <template v-slot:activator="{ props }">
                    <v-list-item v-bind="props">
                      {{ item.title }}
                    </v-list-item>
                  </template>

                  <div v-for="(subItem, subIndex) in item.subMenuItems" :key="subIndex">
                    <NuxtLink :to="subItem.path" class="text-decoration-none color-contrast" :aria-label="subItem.title">
                      <v-list-item exact :active="$route.path.startsWith(subItem.path)" link>
                        <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                      </v-list-item>
                    </NuxtLink>
                  </div>
                </v-list-group>
              </template>
            </v-list>
          </v-card>
        </template>
      </v-menu>
    </div>

    <NuxtLink to="/" class="mx-3 d-flex align-items-center" aria-label="logo">
      <div class="logo"></div>
    </NuxtLink>

    <div class="visible-on-web">
      <!-- Loop through mainMenuItems -->
      <template v-for="(item, index) in mainMenuItems" :key="index">
        <!-- If item has subMenuItems, render as a menu -->
        <v-menu open-on-hover scroll-strategy="close" location="bottom" v-if="item.subMenuItems">
          <template v-slot:activator="{ props, isActive }">
            <v-btn
                :class="{ 'btn-active': isBtnActive(item.path, item.subMenuItems) }"
                v-bind="props"
                color="contrast"
                size="large"
                variant="text"
                rounded="0"
                :append-icon="isActive ? 'mdi-chevron-up' : 'mdi-chevron-down'"
            >
              {{ item.title }}
            </v-btn>
          </template>
          <v-list bg-color="primary" rounded="0">
            <template v-for="(subItem, subIndex) in item.subMenuItems" :key="subIndex">
              <NuxtLink :to="subItem.path" class="text-decoration-none color-contrast" :aria-label="subItem.title">
                <v-list-item exact :active="$route.path.startsWith(subItem.path)" link color="contrast">
                  <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                </v-list-item>
              </NuxtLink>
            </template>
          </v-list>
        </v-menu>

        <!-- If item does not have subMenuItems, render as a button -->
        <NuxtLink :to="item.path" class="text-decoration-none color-contrast" :aria-label="item.title" v-else>
          <v-btn
              :class="{ 'btn-active': isBtnActive(item.path) }"
              color="contrast"
              size="large"
              variant="text"
              rounded="0"
          >
            {{ item.title }}
          </v-btn>
        </NuxtLink>
      </template>
    </div>

    <v-spacer></v-spacer>
    <div class="me-2">
      <input type="checkbox" id="theme-toggle" v-model="isDarkTheme" @click="toggleTheme">
      <label for="theme-toggle"><span></span></label>
    </div>

    <!-- If user is not logged in -->
    <div v-if="!user">
      <NuxtLink to="/authenticate" class="text-decoration-none color-contrast" aria-label="authenticate" v-if="isWideScreen">
        <v-btn
            color="contrast"
            size="large"
            variant="outlined"
            rounded="0"
            prepend-icon="mdi-account-circle-outline"
            aria-label="authenticate"
        >
          {{ translation(translationList.login, 'Intră în cont') }}
        </v-btn>
      </NuxtLink>
      <NuxtLink to="/authenticate" class="text-decoration-none color-contrast" aria-label="authenticate" v-else>
        <v-btn
            color="contrast"
            size="large"
            variant="outlined"
            rounded="0"
            icon="mdi-account-circle-outline"
            aria-label="authenticate"
        >
        </v-btn>
      </NuxtLink>
    </div>
    <!-- If user is logged in -->
    <div v-else>
      <v-menu location="bottom" scroll-strategy="close">
        <template v-slot:activator="{ props }">
          <v-btn
              v-if="isWideScreen"
              v-bind="props"
              color="contrast"
              size="large"
              variant="outlined"
              rounded="0"
              prepend-icon="mdi-account-circle-outline"
              aria-label="account"
          >
            <span>{{ user.full_name }}</span>
          </v-btn>
          <v-btn
              v-else
              v-bind="props"
              color="contrast"
              style="max-height: 44px; max-width: 44px;"
              variant="outlined"
              rounded="0"
              icon="mdi-account-circle-outline"
              aria-label="account"
          >
          </v-btn>
        </template>
        <template v-slot:default>
          <v-card class="shadow-lg px-3 py-3" rounded="0" style="left: +4px;"  min-width="300px">
            <div class="pa-2">
              <div class="flex-column">
                <div>
                  {{ translation(translationList.greetings, 'Salut') }}, <span
                    class="fw-600">{{ user.full_name }}</span>
                </div>
                <div>
                  <span style="font-style: italic;">{{ user.email }}</span>
                </div>
              </div>
            </div>
            <v-divider></v-divider>
            <v-list dense class="py-0">
              <template v-for="(item, index) in accountMenuItems" :key="index">
                <NuxtLink :to="item.path" class="text-decoration-none color-contrast" :aria-label="item.title">
                  <v-list-item
                      :prepend-icon="item.icon"
                      rounded="0"
                      exact :active="$route.path.startsWith(item.path)" link
                  >
                    {{ item.title }}
                  </v-list-item>
                </NuxtLink>
              </template>
            </v-list>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn block
                     variant="outlined"
                     rounded='0'
                     color="error"
                     @click="logout"
              >
                Logout
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-menu>
    </div>
  </v-card>
</template>

<script lang="ts">
import {defineComponent, onMounted, onBeforeMount, onUnmounted, computed, ref} from 'vue';
import {useAuthStore} from '~/plugins/store/auth';
import {useTranslationStore, translation} from '~/plugins/store/translations';
import {useRouter, useRoute} from 'vue-router';
import type {MenuItem, TranslationList} from "~/types/Utils";
import {reloadNuxtApp} from "nuxt/app";
import {useTheme} from 'vuetify';
import {useProductStore} from "~/plugins/store/product";
import type {ProductStatuses} from "~/types/Product";
import {useSystemStore} from "~/plugins/store/system";

export default defineComponent({
  setup() {
    const theme = useTheme();

    const toggleTheme = () => {
      systemStore.isThemeChangedManually = true;
      systemStore.isDarkTheme = !systemStore.isDarkTheme;
      theme.global.name.value = systemStore.isDarkTheme ? 'brokerDarkTheme' : 'brokerTheme';
    };

    const authStore = useAuthStore();
    const user = computed(() => authStore.getUser());

    const productStore = useProductStore();
    const productStatuses = ref<ProductStatuses>(productStore.productStatuses);

    const translationStore = useTranslationStore();
    const translationList = ref<TranslationList>({}); // Initialize translations as a reactive reference
    const mainMenuItems = ref<MenuItem[]>([]);

    const route = useRoute();

    const menuOpen = ref(false);
    const isOpen = ref(true);
    const systemStore = useSystemStore();
    const isDarkTheme = ref(systemStore.isDarkTheme);

    //Loading translations when component is mounted
    onMounted(async () => {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches && !systemStore.isThemeChangedManually) {
        systemStore.isDarkTheme = true;
        isDarkTheme.value = true;
        theme.global.name.value = 'brokerDarkTheme';
      } else {
        theme.global.name.value = systemStore.isDarkTheme ? 'brokerDarkTheme' : 'brokerTheme';
      }

      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
        if (!systemStore.isThemeChangedManually) {
          systemStore.isDarkTheme = event.matches;
          theme.global.name.value = systemStore.isDarkTheme ? 'brokerDarkTheme' : 'brokerTheme';
        }
      });

      translationList.value = await translationStore.getTranslations('ro', 'dashboard');

      const subMenuItems = [];
      if (productStatuses.value.is_rca_available) {
        subMenuItems.push({
          title: translation(translationList.value.rca, 'RCA'),
          path: "/rca"
        });
      }
      if (productStatuses.value.is_house_insurance_available) {
        subMenuItems.push({
          title: translation(translationList.value.home_insurance, 'Asigurare de locuință'),
          path: "/house"
        });
      }
      if (productStatuses.value.is_travel_available) {
        subMenuItems.push({
          title: translation(translationList.value.travel_insurance, 'Asigurare de călătorie'),
          path: "/travel"
        });
      }

      mainMenuItems.value = [
        {
          title: translation(translationList.value.home, 'Home'),
          path: "/"
        },
        {
          title: translation(translationList.value.comparator, 'Comparator'),
          path: "#",
          subMenuItems: subMenuItems.length ? subMenuItems : null,
        },
        {
          title: translation(translationList.value.about_us, 'Despre noi'),
          path: "/info/about-us"
        },
        {
          title: translation(translationList.value.contact, 'Contact'),
          path: "/contact-us"
        },
      ];
    });

    //Define menu with account menu items
    const accountMenuItems = ref([
      {
        title: translation(translationList.value.my_profile, 'Profil'),
        icon: "mdi-account",
        path: "/account/profile"
      },
      // {
      //   title: translation(translationList.value.history, 'Istoric'),
      //   icon: "mdi-history",
      //   path: ""
      // },
    ]);

    //Marking the menu buttons and menu dropdown buttons as active
    const isBtnActive = (path: String, subMenuItems = []) => {
      // Check if the current path matches the defined path for the menu
      if (path && route.path === path) {
        return true;
      }
      // Check if the current path matches any of the submenu paths
      return subMenuItems.some(subItem => route.path === subItem.path);
    };

    //We need the width of the screen in order to change the account button style
    const width = ref(0);
    const isWideScreen = computed(() => width.value > 600);

    const handleResize = () => {
      if (typeof window !== 'undefined') {
        width.value = window.innerWidth;
      }
    };

    onMounted(() => {
      handleResize(); // Set initial width
      window.addEventListener('resize', handleResize);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize);
    });

    const logout = async () => {
      await authStore.logout();
      reloadNuxtApp();
    };

    return {
      user,
      translationList,
      mainMenuItems,
      accountMenuItems,
      translation,
      isBtnActive,
      isWideScreen,
      logout,
      toggleTheme,
      productStatuses,
      menuOpen,
      isOpen,
      systemStore,
      isDarkTheme
    };
  },
});
</script>

<style scoped>

</style>
