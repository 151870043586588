import { defineNuxtPlugin } from '#app'
import Pusher from 'pusher-js'
import {useAuthStore} from "~/plugins/store/auth";

export default defineNuxtPlugin(async (nuxtApp) => {
    const config = useRuntimeConfig();
    const authStore = useAuthStore();

    const accessToken = authStore.getAccessToken();

    const pusher = new Pusher(config.public.pusherAppKey, {
        cluster: 'eu',
        authEndpoint: `${config.public.apiBaseUrl}/pusher/auth`,
        auth: {
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        },
    });

    nuxtApp.provide('pusher', pusher);
});
