// plugins/globalMethods.ts
import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin(nuxtApp => {
    // Adaugă metoda scrollToTop ca o proprietate globală
    nuxtApp.provide('scrollToTop', () => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    });
});
