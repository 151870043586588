import { defineNuxtPlugin } from '#app';
import { VueReCaptcha } from 'vue-recaptcha-v3';

export default defineNuxtPlugin((nuxtApp) => {
    const { vueApp } = nuxtApp;
    const config = useRuntimeConfig();
    const siteKey = config.public.recaptchaSiteKey;

    if (!siteKey) {
        console.error('reCAPTCHA site key is not defined.');
        return;
    }

    vueApp.use(VueReCaptcha, {
        siteKey: siteKey,
        loaderOptions: {
            autoHideBadge: true,
            explicitRenderParameters: {
                badge: 'bottomright',
            },
        },
    });
});
