import {createVuetify, type ThemeDefinition} from 'vuetify'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/dist/vuetify-labs.css'
import 'vuetify/styles'

const brokerTheme: ThemeDefinition = {
    dark: false,
    colors: {
        'contrast': '#121212',
        background: '#FFFFFF',
        surface: '#FFFFFF',
        'ghost-white': '#FFFFFF',

        primary: '#FFCC27',
        'primary-light': '#F9F3EF',
        'primary-accent': '#FFFBE8',

        secondary: '#00DDF4',
        'secondary-light': '#ADEEF9',

        tertiary: '#A3C737',
        'tertiary-light': '#EAF6D4',
        'tertiary-accent': '#354112',

        error: '#B00020',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
        'disabled-light': '#F0F0F0',
        'disabled-dark': '#CACACA',
    },
    variables: {
        'logo-url': 'url(/images/logo-dark.webp)',
        'icon-house-url': 'url(/images/icons/house-dark.webp)',
        'icon-travel-url': 'url(/images/icons/travel-dark.webp)',
        'icon-rca-url': 'url(/images/icons/rca-dark.webp)',
        'logo-lih-url': 'url(/images/logo-lih-dark.webp)',
    },
}

const brokerDarkTheme: ThemeDefinition = {
    dark: true,
    colors: {
        "contrast": "#F0F0F0",
        "background": "#121212",
        "surface": "#1E1E1E",
        'ghost-white': '#F3F4F6',

        "primary": "#c09300",
        "primary-light": "#271e00",
        "primary-accent": "#1c1700",

        "secondary": "#00DDF4",
        "secondary-light": "#007B8A",

        "tertiary": "#A3C737",
        "tertiary-light": "#A3C737",
        'tertiary-accent': '#354112',

        error: '#CF6679',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
        'disabled-light': '#111111',
        'disabled-dark': '#CACACA',
    },
    variables: {
        'logo-url': 'url(/images/logo-light.webp)',
        'icon-house-url': 'url(/images/icons/house-light.webp)',
        'icon-travel-url': 'url(/images/icons/travel-light.webp)',
        'icon-rca-url': 'url(/images/icons/rca-light.webp)',
        'logo-lih-url': 'url(/images/logo-lih-light.webp)',
    },
}

export default defineNuxtPlugin((app) => {
    const vuetify = createVuetify({
        theme: {
            defaultTheme: 'brokerTheme',
            themes: {
                brokerTheme,
                brokerDarkTheme,
            },
        },
        ssr: true,
    })
    app.vueApp.use(vuetify)
})