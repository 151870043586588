import { default as profileCyQDkm5msUMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/profile.vue?macro=true";
import { default as authenticateNBSNBOJ2xeMeta } from "/opt/atlassian/pipelines/agent/build/pages/authenticate.vue?macro=true";
import { default as contact_45usfh16CA1puhMeta } from "/opt/atlassian/pipelines/agent/build/pages/contact-us.vue?macro=true";
import { default as housep6bLGXudmKMeta } from "/opt/atlassian/pipelines/agent/build/pages/house.vue?macro=true";
import { default as indexOD9t3F2bSJMeta } from "/opt/atlassian/pipelines/agent/build/pages/index.vue?macro=true";
import { default as _91_91slug_93_935rmjEF4MLgMeta } from "/opt/atlassian/pipelines/agent/build/pages/info/[[slug]].vue?macro=true";
import { default as maintenance5s9pKqcvvoMeta } from "/opt/atlassian/pipelines/agent/build/pages/maintenance.vue?macro=true";
import { default as _91_91vin_93_93edrFOBR2MRMeta } from "/opt/atlassian/pipelines/agent/build/pages/rca/[[registration_plate]]/[[vin]].vue?macro=true";
import { default as travelXoTodzSx64Meta } from "/opt/atlassian/pipelines/agent/build/pages/travel.vue?macro=true";
export default [
  {
    name: "account-profile",
    path: "/account/profile",
    meta: profileCyQDkm5msUMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "authenticate",
    path: "/authenticate",
    meta: authenticateNBSNBOJ2xeMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/authenticate.vue").then(m => m.default || m)
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "house",
    path: "/house",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/house.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "info-slug",
    path: "/info/:slug?",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/info/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "rca-registration_plate-vin",
    path: "/rca/:registration_plate?/:vin?",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/rca/[[registration_plate]]/[[vin]].vue").then(m => m.default || m)
  },
  {
    name: "travel",
    path: "/travel",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/travel.vue").then(m => m.default || m)
  }
]