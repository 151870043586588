import { useReCaptcha } from 'vue-recaptcha-v3';

const recaptcha = async (recaptchaInstance: ReturnType<typeof useReCaptcha>) => {
    // Optional: wait for the reCaptcha to load
    await recaptchaInstance?.recaptchaLoaded();

    // Get the token, a custom action could be added as an argument to the method
    return recaptchaInstance?.executeRecaptcha('form');
};

export { recaptcha };