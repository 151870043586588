import {defineStore} from 'pinia';
import axios from 'axios';
import type {LeasingCompany, RcaVehicleForm} from "~/types/Vehicle";
import type {RcaCustomerForm} from "~/types/Customer";
import type {RcaAvailability, RcaForm, RcaInsuranceCompany} from "~/types/Rca";

export const useRcaStore = defineStore('rca', {
    state: () => ({
        availabilities: [] as RcaAvailability[],
        _fetchAvailabilitiesPromise: null as Promise<any> | null,
        leasingCompanies: [] as LeasingCompany[],
        _fetchRcaLeasingCompaniesPromise: null as Promise<any> | null,
        insuranceCompanies: [] as RcaInsuranceCompany[],
        _fetchInsuranceCompaniesPromise: null as Promise<any> | null,
        _sendOffersToUserPromise: null as Promise<any> | null,
        allInsuranceCompanies: [] as RcaInsuranceCompany[],
        _fetchAllInsuranceCompaniesPromise: null as Promise<any> | null,
    }),
    actions: {
        async fetchAvailabilities() {
            if (this._fetchAvailabilitiesPromise) return this._fetchAvailabilitiesPromise;

            this._fetchAvailabilitiesPromise = (async () => {
                try {
                    const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/v1/rca-availabilities`);
                    if (response.data.data) {
                        this.availabilities = response.data.data;
                    }
                } catch (error) {
                    throw error;
                } finally {
                    this._fetchAvailabilitiesPromise = null;
                }
            })();

            return this._fetchAvailabilitiesPromise;
        },
        async getAvailabilities() {
            if (this.availabilities.length === 0) {
                await this.fetchAvailabilities();
            }
            return this.availabilities;
        },
        async fetchRcaLeasingCompanies() {
            if (this._fetchRcaLeasingCompaniesPromise) return this._fetchRcaLeasingCompaniesPromise;

            this._fetchRcaLeasingCompaniesPromise = (async () => {
                try {
                    const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/v1/rca-leasing-companies`);
                    if (response.data.data) {
                        this.leasingCompanies = response.data.data;
                    }
                } catch (error) {
                    throw error;
                } finally {
                    this._fetchRcaLeasingCompaniesPromise = null;
                }
            })();

            return this._fetchRcaLeasingCompaniesPromise;
        },
        async getRcaLeasingCompanies() {
            if (this.leasingCompanies.length === 0) {
                await this.fetchRcaLeasingCompanies();
            }
            return this.leasingCompanies;
        },
        async fetchRcaInsuranceCompanies(vehicleTypeId: number, availabilities: string) {
            if (this._fetchInsuranceCompaniesPromise) return this._fetchInsuranceCompaniesPromise;

            this._fetchInsuranceCompaniesPromise = (async () => {
                try {
                    const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/v1/rca-insurance-companies/list?vehicle_type_id=${vehicleTypeId}&availabilities=${availabilities}`);
                    if (response.data.data) {
                        this.insuranceCompanies = response.data.data;
                    }
                } catch (error) {
                    throw error;
                } finally {
                    this._fetchInsuranceCompaniesPromise = null;
                }
            })();

            return this._fetchInsuranceCompaniesPromise;
        },
        async fetchAllRcaInsuranceCompanies() {
            if (this._fetchAllInsuranceCompaniesPromise) return this._fetchAllInsuranceCompaniesPromise;

            this._fetchAllInsuranceCompaniesPromise = (async () => {
                try {
                    const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/v1/rca-insurance-companies`);
                    if (response.data.data) {
                        this.allInsuranceCompanies = response.data.data;
                    }
                } catch (error) {
                    throw error;
                } finally {
                    this._fetchAllInsuranceCompaniesPromise = null;
                }
            })();

            return this._fetchAllInsuranceCompaniesPromise;
        },
        async getAllRcaInsuranceCompanies(): Promise<RcaInsuranceCompany[]> {
            if (this.allInsuranceCompanies.length === 0) {
                await this.fetchAllRcaInsuranceCompanies();
            }
            return this.allInsuranceCompanies;
        },
        async fetchOffers(
            insuranceCompany: RcaInsuranceCompany,
            vehicleForm: RcaVehicleForm,
            customerForm: RcaCustomerForm,
            rcaForm: RcaForm,
            accessToken: string|null, token: string|undefined) {
            try {
                const config = accessToken ? {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                } : {};

                const response = await axios.post(`${import.meta.env.VITE_API_BASE_URL}/api/v1/rca-offers`, {
                    insurance_company: insuranceCompany,
                    vehicle: vehicleForm,
                    customer: customerForm,
                    rca: rcaForm,
                    token,
                }, config);
                if (response.data.data) {
                    return response.data.data;
                }
            } catch (error) {
                return null;
            }
        },
        async sendOffersToUser(offers: { [key: string]: any },
                               insuranceCompanies: RcaInsuranceCompany[],
                               isDeducted: boolean,
                               email: string,
                               vehicleId: number,
                               accessToken: string|null,
                               token: string|undefined) {
            if (this._sendOffersToUserPromise) return this._sendOffersToUserPromise;

            this._sendOffersToUserPromise = (async () => {
                try {
                    const config = accessToken ? {
                        headers: {
                            Authorization: `Bearer ${accessToken}`
                        }
                    } : {};

                    const response = await axios.post(`${import.meta.env.VITE_API_BASE_URL}/api/v1/rca-offers/send`, {
                        rca_offers_data: offers,
                        email,
                        is_deducted: isDeducted,
                        vehicle_id: vehicleId,
                        insurance_companies: insuranceCompanies,
                        token,
                    }, config);
                    if (response.data.data) {
                        return response.data.data;
                    }
                } catch (error) {
                    throw error;
                } finally {
                    this._sendOffersToUserPromise = null;
                }
            })();

            return this._sendOffersToUserPromise;
        },
    },
    persist: {
        storage: persistedState.sessionStorage,
        paths: [
            'availabilities',
            'leasingCompanies',
            'allInsuranceCompanies',
        ],
        key: 'rcaStore',
    }
});
