import { storeToRefs } from 'pinia';
import { useAuthStore } from "~/plugins/store/auth";

export default defineNuxtRouteMiddleware((to) => {
    const { authenticated } = storeToRefs(useAuthStore()); // make authenticated state reactive
    const authResponse = useCookie('authResponse'); // get token from cookies
    if (authResponse.value) {
        // check if value exists
        authenticated.value = true; // update the state to authenticated
    }

    // if user doesn't exist redirect to log in
    if (!authResponse.value) {
        abortNavigation();
        return navigateTo('/authenticate');
    }
});
